.editTimesheet {
  width: 80%;
  z-index: 200;

  padding-top: 1em;
  position: absolute;
  top: 0;
  left: 10%;
  top: 50vh;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
}
.jobPhotoLoading {
  min-height: 200px;
}
.selectedPhoto {
  border: 5px solid red;
}
.jobPhotoDisplay {
  cursor: pointer;
}
.jobPhotoImage {
  width: 100%;
  height: auto;
}

.clock {
  margin: 0.5em;
}

.timepicker {
  width: 50%;
}

.clock-entertime {
  margin: 0.5em;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
}

.hint {
  margin-bottom: 16px;
  font-size: 14px;
  color: gray;
  font-style: italic;
  text-align: center;
}
