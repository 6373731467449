.moreOptions {
  color: #fff;
}
.toolbar_navigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}
.toolbar {
  z-index: 2;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 56px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
}
.toolbarLogo {
  margin-left: 1rem;
}
.moreOptionsMenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #617487;
  box-shadow: -3px 5px 7px rgba(0, 0, 0, 0.5);
}
.moreOptionsMenu li {
  padding: 1rem;
}
.moreOptionsMenu {
  position: fixed;
  top: 56px;
  right: 0;
}
.moreOptions {
  cursor: pointer;
  width: 25px;
  padding: 0 10px;
}
.toolbarLinks ul li {
  padding: 1rem 1rem !important;
}
.toolbarBtn {
  padding: 0 !important;
  text-decoration: none;
  color: #fff;
}
.toolbarBtn:hover {
  color: #fcbc55;
}
.spacer {
  flex: 1;
}
