.addEmployee {
  max-width: 500px;
  width: 80%;
  z-index: 200;
  margin: auto;
  padding-top: 1em;
  position: absolute;
  top: 20vh;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
  left: 50%;
  margin-left: -20%;
}
.spinner {
  font-size: 24px;
}

@media only screen and (max-width: 600px) {
  .addEmployee {
    left: 0;
    margin-left: 5px;
  }
}
