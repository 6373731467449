.tabLink {
  text-decoration: none;
  color: rgb(155, 154, 154);
}

.tabs {
  margin: 0 20%;
}
.reportsPanelHeading {
  margin: 0 20%;
  text-align: left;
}
.reportsPanel {
  overflow: auto;
}
.returnLink {
  position: relative;
  margin: 0.1em;
}
.timesheetListItem {
  cursor: pointer;
}
.jobReport {
  padding: 2em;
}
.light {
  background-color: rgb(240, 240, 240);
}
.dark {
  background-color: #ccc;
}
.react-datepicker-popper {
  z-index: 50000;
}
.viewReport {
  height: 100%;
}
.viewPrintableReport {
  position: fixed;
  width: 100%;
  height: calc( 100vh - 64px - 50px );
  background-color: #fff;
  top: 64px;
  overflow-y: auto;
  left: 0;
  padding: 1em;
  z-index: 5;
}
.printableReportControls {
  position: fixed;
  width: 100%;
  height: 50px;
  bottom: 0;
  left: 0;
  padding-top: 7px;
  background: #ccc;
}
.viewExpenseReportPic {
  width: auto;
  max-width: 95%;
  margin: auto;
  height: auto;
}
.openReport {
  cursor: pointer;
}
.workersAndSignatures {
  margin-bottom: 6em;
}
.timesheetSignature {
  margin: 0 auto;
}
.timesheetReportSignature {
  margin: 1em;
  width: 300px;
  border: 1px solid black;
}
.timesheetReportSignature img {
  max-width: 300px;
  height: auto;
}
.timesheetTableButton {
  margin: 5px;
}
.reportPipeForm {
  border: 1px solid black;
  margin: 1em;
  padding: 1em;
}
.viewJobPhoto {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.viewImage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  background: #fff;
}
.controlBar {
  width: 100%;
  height: 100px;
  text-align: center;
}
.boreValidationSignature {
  width: 300px;
}
.borePreShotSignatures {
  max-height: 300px;
}
.charge {
  border: 1px solid black;
}
.jobLinKButton {
  display: inline-block;
}
.jobLinkButtonContainer {
  display: inline-block;
}

.weeklyTable {
  border-collapse: collapse;
  width: 100%;
}
.weeklyTable td,
.weeklyTable th {
  padding: 0.25rem !important;
}
.bottom-border {
  border-bottom: 3px dashed rgb(141, 140, 140);
}
.viewOptions {
  background: #ccc;
  width: 400px;
  height: 200px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  padding: 2em;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
}
.viewOptionsButton {
  margin: 0.5em;
}
.reportContent {
  padding: 2em;
}
@media only screen and (max-width: 600px) {
  .tabs {
    width: 100%;
    margin: 0;
  }
  .jobReport {
    padding: 0;
  }
}