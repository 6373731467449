.toggle-button {
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0;
  justify-content: space-around;
}
.toggle-button:focus {
  outline: none;
}
.toggleButtonLine {
  width: 30px;
  height: 2px;
  background: #494949;
  border-radius: 2px;
}
