.addExpenseReport {
  width: 80%;
  z-index: 200;

  padding-top: 1em;
  position: absolute;
  top: 0;
  left: 10%;
  top: 10vh;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
}
.receiptPhoto {
  max-width: 200px;
}

.submitTimesheet {
  margin-bottom: 5em;
}

@media only screen and (max-width: 600px) {
  .addExpenseReport {
    width: 100%;
    z-index: 200;

    padding-top: 1em;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
    padding: 1em;
  }
  .addExpenseReportContent {
    margin-top: 2em;
  }
}
