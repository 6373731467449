.react-time-picker__clock,
.react-time-picker__clock--open {
  height: auto !important;
  position: absolute;
}
.enterTime {
  height: 400px !important;
}
.buttonIcon {
  font-size: 25px;
  padding: 5px;
  color: #fff;
}
.red {
  color: red;
}
.jobForm {
  width: 100%;
  min-height: 100%;
  background: #ccc;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  z-index: 300;
}
.titleMargin {
  margin-top: 1em;
}
.jobFormRow {
  width: 80%;
  margin-left: 10%;
}
.jobFormContainer {
  margin-top: 50px;
}
.exit {
  right: 40px !important;
  position: absolute !important;
}
.employeeList li {
  cursor: pointer;
}
.employeeList li:hover {
  background-color: rgb(121, 121, 121);
}
.addEmployeeButton {
  margin: 1em;
}
.addEmployeeTimesheet {
  max-width: 500px;
  max-height: 350px;
  width: 80%;
  z-index: 200;
  margin: auto;
  padding-top: 1em;
  position: fixed;
  overflow: scroll;
  top: 20vh;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
  left: 50%;
  margin-left: -20%;
}
.pipeForm {
  border: 1px solid black;
  margin: 1em;
  padding: 1em;
}
.notes {
  width: 100%;
  min-height: 100px;
}
.pipeFormText {
  margin: 0.5em;
  width: 100%;
}

.digitalSignature {
  display: inline-block;
  border: 1px solid black;
  padding: 1em;
  margin: 1em;
  overflow: hidden;
}
.digitalSignature p {
}
.digitalSignature input {
  margin-top: 1em;
}
.overwrite {
  bottom: 10px;
}

.workerSignature {
  margin: 0em 1em;
  padding: 1em;
  border: 1px solid #fff;
}

.tailboard {
  padding-bottom: 5em;
}
.didYouKnow li i {
  margin: 0 0.5em;
}
.jobPhotos {
  border: 1px solid #ccc;
}
.clock {
  border: 1px solid #ccc;
}
.jobDocuments {
  border: 1px solid #ccc;
}
.safety {
  border: 1px solid #ccc;
}
.covid {
  border: 1px solid #ccc;
}
.wsm {
  position: fixed;
  width: 95%;
  margin: 0 auto;
  height: 100%;
  top: 0;
  overflow: scroll;
}
.formRow {
  text-align: center;
}
.getImage {
  width: 80%;
  min-height: 80vh;
  z-index: 200;
  padding-top: 1em;
  position: fixed;
  top: 10%;
  left: 10%;
  background-color: #fff;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
}
.addImage {
  width: 80%;
}
.selected {
  border: 2px solid green;
  padding: 0.5em;
}
.selectedJobPhoto {
  border: 3px solid red;
  width: 80%;
  z-index: 2;
}
.selectedJobPhoto img {
  width: 100%;
  margin: 1em;
}
.jobPhoto img {
  width: 100%;
}
.jobPhoto {
  width: 100%;
  margin: 1em;
}
.jobsContainer {
  position: relative;
  margin-top: 1em;
}
.jobPhotoContainer {
  position: relative;
  border: 1px solid black;
}
.jobControls {
  position: relative;
  margin-bottom: 1em;
}

.jobControls button {
  margin-right: 1em;
}

.jobControls button:last-of-type {
  margin-right: 0em;
}

.clockOutConfirmation {
  width: 80%;
  z-index: 200;

  padding-top: 1em;
  position: absolute;
  top: 0;
  left: 10%;
  top: 50vh;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
}
.addImageComponent {
  position: absolute;
  z-index: 8008;
  top: 30%;
  width: 80%;
  left: 10%;
  background-color: cadetblue;
}
.arrayImage img {
  width: 150px;
}
.icon {
  width: 100px;
}
.curbBox {
  text-align: center;
}
.wsmSelect {
  overflow: scroll;
}
@media only screen and (max-width: 800px) {
  .addEmployeeTimesheet {
    left: 25%;
  }
  .selectedJobPhoto img {
    width: 100%;

    height: auto;
  }
  .jobPhoto img {
    width: 100%;

    height: auto;
    vertical-align: middle;
  }
}
