.dashboardContent {
  padding-top: 80px;
  text-align: center;
  background-color: #f5f5dc;

  padding-bottom: 50px;
  width: 100%;
  height: 100%;
}
.copyright {
  position: relative;
}
.dashboardPanel {
}
.dashboardPanelHeading {
  text-align: left;
}

.dashboardPanelHeading-flex {
  display: flex;
  justify-content: space-between;
}

.dashboardControls {
  width: 100%;
}

.dashboardControls button {
  margin-right: 1em;
}
.dashboardControls button:last-of-type {
  margin-right: 0em;
}

.dashboardButton {
  cursor: pointer;
  margin: 0.3em;
}
.dashboardButton:hover {
  background-color: #999999;
}
.reportLink {
  margin: 1em;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
}
.recentReports {
  background-color: #5f5f5f;
  padding: 10px;
}
.recentReportsText {
  padding: 10px;
  color: #fff;
}
@media only screen and (max-width: 400px) {
  .dashboardPanel {
    width: 95%;
    margin: 0px auto;
  }
}
@media only screen and (min-width: 401px) {
  .dashboardPanel {
    width: 95%;
    margin: 0px auto;
  }
}
@media only screen and (min-width: 701px) {
  .dashboardPanel {
    width: 80%;
    margin-left: 10%;
  }
}
@media only screen and (min-width: 1201px) {
  .dashboardPanel {
    width: 80%;
    margin-left: 10%;
  }
}
