.sideDrawer .MuiPaper-root {  
  background-color: #5f5f5f;
}

.sideDrawerLogo {
  margin: 0px 0px 1rem 1rem;
  width:90%;
  margin:1em 5%;
}

.sideDrawer ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;
  text-align: left;
}
.sideDrawer a {
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none;
}
.sideDrawer li {
  padding: 1rem 0;
  width: 100%;
  border-top: 1px groove rgba(252, 188, 85, 0.3);
  height: 60px;
}
.firstListItem {
  border-top: 0px !important;
}
.drawerIcon {
  height: 30px;
  margin-right: 0.5rem;
}
.imageIcon {
  height: 20px;
  margin-right: 0.5rem;
}
.drawerNavLink {
  margin-left: 1rem;
}
@media only screen and (max-width: 400px) {
  .sideDrawer li {
    height: 40px;
    padding: 0.5rem 0rem;
  }
}
