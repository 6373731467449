body {
  background-color: gray;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.sigContainer {
  width: 80%;
  height: 80%;
  max-width: 500px;
  max-height: 300px;
  margin: 0 auto 30px auto;
}

.sigPad {
  width: 100%;
  height: 100%;

  border: 1px solid grey;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 300px 75px;
  width: 300px;
  height: 75px;
  background-color: white;
  overflow: hidden;
}
.sigImage img {
  width: 100%;
  height: 100%;
}
.signatureGrab {
  width: 75%;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -37.5%;
  background-color: #fff;
  z-index: 3000;
  padding: 1em;
}

.signatureGrab button {
  margin-right: 1em;
}

.signatureGrab button:last-of-type {
  margin-right: 0em;
}

@media only screen and (max-width: 500px) {
  .signatureGrab {
    width: 100%;
    position: fixed;
    left: 3%;
    top: 10vh;
    margin-left: 0 !important;
    background-color: #fff;
  }
}

@media only screen and (max-width: 800px) {
  .signatureGrab {
    width: 100%;
    position: fixed;
    left: 3%;
    margin-left: 0 !important;
    background-color: #fff;
  }
}
