.confirmation {
  position: fixed;
  z-index: 1000;
  top: 30vh;
  left: 50%;
  padding: 1em;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
  margin-left: -20%;
}
.confirmationControls button {
  margin: 0.3em;
}

@media only screen and (max-width: 600px) {
  .confirmation {
    position: absolute;
    z-index: 1000;
    top: 30vh;
    left: 20%;
    padding: 1em;
    box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
    padding: 1em;
    margin-left: -20%;
  }
}
