.appContainer {
    width:100%;
    height:100%;
}
.authInput {
    width: 100%;
  }
  .form-group {
    width: 100%;
    margin: 5px 1em;
  }
  .formCard {
    padding: 1em;
    color: #fff;
    max-width:400px;  
  }
  .login {
    height: 250px;
    padding: 10px 10px 5px 10px;
    
  }
  .submit {
    margin-top: 5px;
  }
  .mainLogo {
      width:75%;
      margin:1em 12.5%;
      max-width:300px;
  }
  @media only screen and (max-width: 800px) {
    .formCard {
      margin-top: 2em !important;
      margin-bottom: 2em !important;
    }
    .login {
      margin-top: 2em !important;
      margin-bottom: 2em !important;
    }
  }
  @media only screen and (min-width: 801px) {
    .formCard {
      margin-top: 5em !important;
      margin-bottom: 5em !important;
    }
    .login {
      margin-top: 5em !important;
      margin-bottom: 5em !important;
    }
  }
  @media only screen and (min-width: 1201px) {
    .formCard {
      margin-top: 6em !important ;
      margin-bottom: 6em !important ;
    }
    .login {
      margin-top: 6em !important ;
      margin-bottom: 6em !important ;
    }
  }
  @media only screen and (min-width: 1601px) {
    .formCard {
      margin-top: 12em !important ;
      margin-bottom: 12em !important ;
    }
    .login {
      margin-top: 12em !important ;
      margin-bottom: 12em !important ;
    }
  }