.App {
  text-align: center;
}
html,
body {
  width: 100%;
  height: 100%;
  background-color: #f5f5dc;
}

.secondaryBackground {
  background-color: #5f5f5f;
}

.MuiInputBase-input {
  padding: 16.5px 14px !important;
  font-size: 20px !important;
}

.MuiDialog-paper {
  padding: 1em;
}

.MuiButton-colorInherit {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #007bff !important;
}

.MuiButton-containedPrimary {
  background-color: #007bff !important;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.MuiIconButton-label:after {
  content: '';
  left: 13px;
  top: 13px;
  height: 15px;
  width: 15px;
  position: absolute;
  background-color: white;
  z-index: -1;
}