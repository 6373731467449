.addJob {
  max-width: 500px;
  width: 80%;
  z-index: 200;
  margin: auto;
  padding-top: 1em;
  position: absolute;
  top: 20vh;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
  left: 50%;
  margin-left: -20%;
}
.sort {
  cursor: pointer;
}
.form-group {
  /* width: 90% !important; */
  margin: auto;
}
.addJobButton {
  margin: 1em auto;
}
.uploadPdf {
  max-width: 500px;
  width: 80%;
  z-index: 200;
  margin: auto;
  padding-top: 1em;
  position: absolute;
  top: 20vh;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
  
 
}
.lowInput{
  overflow:hidden;
}
@media only screen and (max-width: 600px) {
  .form-group {
    width: 90% !important;
    margin: auto;
  }
  .addJob {
    left: 0;
    margin-left: 5px;
  }
}

.uploadPdfContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}